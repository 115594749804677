<template>
  <router-link :to="to" class="room-category-item-wrapper">
    <div class="room-category-item">
      <div
        class="room-category-item__image"
        :style="styleItem(category.image)"
      ></div>
      <div class="room-category-item__content">
        <div class="room-category-item__content--title">
          {{ category.name }}
        </div>
        <div class="room-category-item__content--price">... €</div>
        <div class="room-category-item__content--ckeckbox"></div>
      </div>
    </div>
    <div class="room-category-item-action">
      <v-btn fab color="grey-lighten-4">
        <IconEdit :size="'28'" />
      </v-btn>
    </div>
  </router-link>
</template>

<script>
import { pathImage } from "@/utils/index.js";
export default {
  name: "CategoryListOfRoomItem",
  components: {
    IconEdit: () => import("~cp/Icons/IconEdit"),
  },
  props: {
    category: {
      type: Object | Number,
      required: true,
    },
  },
  computed: {
    styleItem() {
      return (path) => {
        let pathUrl = pathImage(path);
        return pathUrl ? { backgroundImage: `url(${pathUrl})` } : null;
      };
    },
    to() {
      return {
        name: "ServiceCategoryId",
        params: {
          categoryId: this.category.id,
        },
        query: { ...this.$route.query }
      }
    }
  },
};
</script>

<style>
</style>